import { Link } from 'gatsby';
import React from 'react';

export default function GameButton(props) {
  return (
    <div className="gameButton">
        <Link to={"/games/" + props.linkName}>
          <img className="gameButtonBackgroundImage" src={props.bgImage} alt=""/>
          <div className="gameThumbnailOverlay">
              <b>{props.gameName}</b>
          </div>
        </Link>
    </div>
  );
}
