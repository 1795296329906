import React from 'react';
import { Helmet } from 'react-helmet';
import GameButton from '../../components/GameButton';
import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import * as styles from '../../styles/games.module.css'

export default function Games() {
  return (
    <Layout>
      <Helmet>
        <meta charSet='urtf-8'/>
        <title>Venture Games - Games</title>
      </Helmet>
      
      <PageTitle title="Games" bgImage="/ShooterBackgroundImage1.jpg"/>
      <div className={styles.games}>
        <GameButton bgImage="/ShooterGameIcon.png" gameName="Shooter: Space Age!" linkName="shooter-space-age"/>
        <GameButton bgImage="/RotateItGameIcon.png" gameName="COMING SOON!" linkName=""/>
      </div>
    </Layout>
  )
}
