import React from "react"
import { Parallax } from "react-parallax"

export default function pageTitle(props) {
const titleStyle = {
  color: 'white',
  left: '50%',
  top: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
}

  return (
    <div className="title">
      <Parallax bgImage={props.bgImage} strength={200}>
        <div style={{ height: 200, background: 'rgba(0,0,0, 0.5)'}}>
          <h1 style={titleStyle}>{props.title}</h1>
        </div>
      </Parallax>
    </div>
  )
}
